/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { clsx } from 'clsx';

// Imports for internal (to the monorepo) libraries go here
import { Heading, Types } from '@marriott/mi-ui-library';
import { StyledAwardsGallery } from './AwardsGallery.styles';
import { AwardRecognitionProps, AwardsGalleryProps } from './AwardsGallery.types';
import { AwardsGalleryRow } from './AwardsGalleryRow';
import { AwardListProps } from './AwardsGalleryRow/AwardsGalleryRow.types';

export const AwardsGalleryConfig = {
  emptyLabel: 'AwardsGallery',
  isEmpty: () => true,
  resourceType: `/components/content/AwardsGallery`,
};

export const AwardsGalleryComp = (props: AwardsGalleryProps) => {
  const { awardGalleryList, title, variation, appliedCssClassNames } = props;
  const getAwardList = (awardList: any) => {
    const awardArray = [];
    const variationNumber = parseInt(variation, 10);
    for (let awardIndex = 1; awardIndex <= variationNumber; awardIndex++) {
      const listOfAllAwards =
        awardList && awardList?.find((award: AwardListProps) => award.columnNumber === `${awardIndex}`);
      if (listOfAllAwards) {
        awardArray.push(listOfAllAwards);
      } else {
        awardArray.push({
          columnNumber: `${awardIndex}`,
          creditCardType: '',
          awardDetailsList: [],
        });
      }
    }
    return awardArray;
  };

  return (
    <StyledAwardsGallery
      data-component-name="o-cobrand-awards-gallery"
      data-testid="awards-gallery"
      className={clsx('container', `${appliedCssClassNames ? appliedCssClassNames : ''}`)}
    >
      <div className="mx-3 pb-3 pb-md-4 pb-lg-5">
        <div className="mb-lg-5">
          <Heading
            data-testid="award-gallery-title"
            titleText={title}
            variation={Types.headingType.title}
            fontSize={Types.size.small}
            customClass={clsx('text-center pb-4 pb-md-0 mb-md-5 mb-0 mb-0')}
          />
          {awardGalleryList.length &&
            awardGalleryList.map((eachYearAward: AwardRecognitionProps, key: number) => {
              const awardList = eachYearAward.awardsList;
              const awardYear = eachYearAward.year;
              const allAwardsList: AwardListProps[] = getAwardList(awardList);
              return (
                <AwardsGalleryRow variation={variation} allAwardsList={allAwardsList} year={awardYear} key={key} />
              );
            })}
        </div>
      </div>
    </StyledAwardsGallery>
  );
};

export const AwardsGallery = (props: AwardsGalleryProps) => {
  return (
    <EditableComponent config={AwardsGalleryConfig} {...props}>
      <AwardsGalleryComp {...props} />
    </EditableComponent>
  );
};
