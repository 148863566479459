import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledStickyCta = styled.div`
  position: fixed;
  transform: translate(-50%);
  left: 50%;
  text-align: center;
  bottom: 0px;
  z-index: 100;
  width: 100%;
  border: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
    ${baseVariables.color.neutral20};
  background-color: ${baseVariables.color.merch50};
  box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
  @media ${baseVariables.mediaQuery.md} {
    display: none;
  }
  .sticky_cta {
    padding: ${toRem(5)} ${toRem(104)};
  }
`;
