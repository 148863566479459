import React, { FC } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { Heading, Types } from '@marriott/mi-ui-library';
import { CardFeatureListProps, CardFeaturesProps } from './CardFeatures.types';
import { StyledCardFeature } from './CardFeatures.styles';
import { CardFeatureRow } from './CardFeatureRow/CardFeatureRow';
import { getIconColor } from '../../modules/utils';

export const CardFeatureConfig = {
  emptyLabel: 'CardFeatures',
  isEmpty: () => true,
  resourceType: `components/content/awards-gallery`,
};

export const CardFeaturesComp: FC<CardFeaturesProps> = (props: CardFeaturesProps) => {
  const { featuresList, title, columnLayout, isTextCentered, featureTitleFontSize, isTickIconFilled } = props;

  const transformedFeaturesList = featuresList?.map((feature: CardFeatureListProps) => {
    const { featuresSubList } = feature;

    // Create a new featuresSubList with the icon colors applied
    const updatedFeaturesSubList = featuresSubList.map(item => {
      if (!item.featureIcon) return item;
      return {
        ...item,
        featureIconColor: getIconColor(item.featureIcon) || null,
      };
    });

    return {
      ...feature,
      featuresSubList: updatedFeaturesSubList,
    };
  });

  return (
    <StyledCardFeature
      data-testid="card-features"
      data-component-name="o-cobrand-card-features"
      className={clsx('feature')}
    >
      <div className={clsx(columnLayout && columnLayout.toString() === '2-column' ? 'mb-lg-3' : '')}>
        <div
          className={clsx(
            columnLayout && columnLayout.toString() === '2-column'
              ? 'mb-5 pb-2 pb-md-5'
              : 'pb-2 pb-md-5 mb-md-2 mb-lg-3'
          )}
        >
          <Heading
            titleText={title}
            variation={Types.headingType.title}
            fontSize={Types.size.small}
            customClass={clsx(
              'pb-lg-4 pb-0 pb-md-0 mb-lg-4 mb-md-4 mb-3 mx-md-0 mx-3 text-center text-md-left pt-5 mt-0 mt-md-3 mt-lg-5'
            )}
          />
          {transformedFeaturesList &&
            transformedFeaturesList.map((feature: CardFeatureListProps, key: number) => {
              const { featureTitle, infoModalEnabled, modalTitle, modalDescription, modalAriaLabel, featuresSubList } =
                feature;

              const featureItems = {
                featureTitle,
                infoModalEnabled,
                modalTitle,
                modalDescription,
                modalAriaLabel,
                isTextCentered,
                featureTitleFontSize,
                isTickIconFilled,
              };

              return (
                <CardFeatureRow
                  {...featureItems}
                  featuresSubList={featuresSubList}
                  columnLayout={columnLayout}
                  key={key}
                />
              );
            })}
        </div>
      </div>
    </StyledCardFeature>
  );
};

export const CardFeatures = (props: CardFeaturesProps) => {
  return (
    <div className="container">
      <EditableComponent config={CardFeatureConfig} {...props}>
        <CardFeaturesComp {...props} />
      </EditableComponent>
    </div>
  );
};
