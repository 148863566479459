// Imports for external libraries go here.
import { FC } from 'react';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Accordion } from '@marriott/mi-ui-library';
import { CardComparisonAccordionProps, dataJson, categoryJson } from './CardComparisonAccordion.types';
import { StyledCardComparisonAccordion } from './CardComparisonAccordion.styles';
import { TableRow } from '../../molecules/DataCellComponent';
import { usePageContext } from '../../context/PageContext';
import { setCardArray } from '../../modules/utils';
import { useCheckBreakpoint } from '@marriott/mi-ui-library';

export const CardComparisonAccordionConfig = {
  emptyLabel: 'CardComparison',
  isEmpty: () => true,
  resourceType: `/components/content/CardComparison`,
};

// Use named rather than default exports.
export const CardComparisonAccordion: FC<CardComparisonAccordionProps> = (props: CardComparisonAccordionProps) => {
  const isViewportL = useCheckBreakpoint('viewportL');
  const { categoriesToBeShown, group, styleClass } = props;
  const { cardtype1, cardtype2, cardtype3 } = usePageContext();
  const cardIdFromURL = [cardtype1, cardtype2, cardtype3].filter(Boolean);
  const cardData: dataJson[] = setCardArray(props?.pageItems);
  const dataJson = cardData.filter((data: dataJson) => cardIdFromURL?.includes(data.cardId!));
  const getDataList = (category: categoryJson) => {
    const data = dataJson.filter((data: dataJson) => data.categoryId === category.categoryId);
    if (cardIdFromURL) {
      data.sort((a: dataJson, b: dataJson) => cardIdFromURL?.indexOf(a.cardId!) - cardIdFromURL?.indexOf(b.cardId!));
    }
    return data;
  };

  return (
    <StyledCardComparisonAccordion className={styleClass}>
      <Accordion
        id={group.id}
        headerChildren={group.value}
        styleclass="accordion-container"
        isOpen={!isViewportL ? (group.count > 1 ? false : true) : true}
      >
        {categoriesToBeShown.map((category: categoryJson) => {
          const dataList = getDataList(category);
          return (
            <div>
              <TableRow category={category} dataList={dataList} cardIdFromURL={cardIdFromURL} />
            </div>
          );
        })}
      </Accordion>
    </StyledCardComparisonAccordion>
  );
};
