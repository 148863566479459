/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { EditableComponent } from '@adobe/aem-react-editable-components';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Button } from '@marriott/mi-ui-library';
import { CardComparisonProps } from './CardComparison.types';
import { dataJson, categoryJson } from './../../molecules/CardComparisonAccordion/CardComparisonAccordion.types';
import { StyledCardComparison } from './CardComparison.styles';
import { TableRow } from '../../molecules/DataCellComponent';
import { usePageContext } from '../../context/PageContext';
import { setCardArray } from '../../modules/utils';
import { CardComparisonAccordion } from '../../molecules/CardComparisonAccordion';
export const CardComparisonConfig = {
  emptyLabel: 'CardComparison',
  isEmpty: () => true,
  resourceType: `/components/content/CardComparison`,
};

// Use named rather than default exports.
export const CardComparisonComp: FC<CardComparisonProps> = (props: CardComparisonProps) => {
  useEffect(() => {
    const handleScroll = () => {
      let tableHeaderThreshold: any = 0;
      const tableElement = document.querySelector('.table-container');
      tableHeaderThreshold = tableElement?.getBoundingClientRect()?.top;
      const shouldHide = window.scrollY > tableHeaderThreshold;
      const elementsToHide = document.querySelectorAll(
        '.header-category, .platinum-status, .gold-status, .silver-status, .card-image'
      );
      elementsToHide.forEach(el => {
        if (shouldHide) {
          el.classList.add('d-none');
          el.classList.add('d-md-block');
        } else {
          el.classList.remove('d-none');
          el.classList.remove('d-md-block');
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { linkText, linkUrl } = props;

  const { cardtype1, cardtype2, cardtype3 } = usePageContext();
  const cardIdFromURL = [cardtype1, cardtype2, cardtype3].filter(Boolean);
  const selectedCategories = props.idValues.includes(',') ? props.idValues.toString().split(',') : [];
  const categoryData: any[] = props?.pageItems?.categories ? Object.values(props?.pageItems?.categories) : [];
  const cardData: dataJson[] = setCardArray(props?.pageItems);
  const filteredCategory = selectedCategories.length
    ? selectedCategories
    : categoryData.map(category => category.categoryId);
  const selectedCardsData = cardData.filter((data: dataJson) => cardIdFromURL?.includes(data.cardId!));
  //remove category entry with empty card data for all selected cards and sort
  const sortedCategory = categoryData
    .filter(category => {
      const hasEntries = selectedCardsData.some(
        data => data.categoryId === category.categoryId && data.dataType !== 'emptyField'
      );
      return hasEntries && filteredCategory.includes(category.categoryId);
    })
    .sort((a, b) => a.categoryId.localeCompare(b.categoryId));
  // setcategoryJson(filteredCategoryWithData);
  const sortedCategoryGroups = sortedCategory
    .reduce((acc, obj, i) => {
      if (
        !acc.some((item: { id: string; value: string }) => item.id === obj.groupId && item.value === obj.groupValue)
      ) {
        acc.push({ id: obj.groupId, value: obj.groupValue, count: i });
      }
      return acc;
    }, [])
    .sort((a: any, b: any) => a.id.localeCompare(b.id))
    .filter((data: any) => data.id !== 'Header');

  // setcategoryGroups(sortedCategoryGroups);
  const dataJson = cardData.filter((data: dataJson) => cardIdFromURL?.includes(data.cardId!));

  const getDataList = (category: categoryJson) => {
    const data = dataJson.filter((data: dataJson) => data.categoryId === category.categoryId);
    if (cardIdFromURL) {
      data.sort((a: dataJson, b: dataJson) => cardIdFromURL?.indexOf(a.cardId!) - cardIdFromURL?.indexOf(b.cardId!));
    }
    return data;
  };

  const groupedCategories = (CategoryJsonList: categoryJson[], groupId: string) => {
    const categories = CategoryJsonList.filter(
      (category: categoryJson) => category.groupId === groupId && category.groupId !== 'Header'
    );
    return categories;
  };

  const headerRow = sortedCategory.filter((category: categoryJson) => category.groupId === 'Header');

  return (
    <StyledCardComparison>
      <div className={'col-12 p-0'}>
        <div className="d-flex justify-content-center justify-content-md-start">
          <Button isLink={true} href={linkUrl} className={'m-button-secondary-icon m-button-m my-5'} testId="backbtn">
            <span className="icon-back-arrow" />
            {linkText}
          </Button>
        </div>
        <div className="table-container col-12 p-0 mt-3 mb-5">
          {dataJson && dataJson.length ? (
            <div className="table-wrapper col-12 p-0">
              {headerRow.map((category: categoryJson) => {
                const dataList = getDataList(category);
                return (
                  <div className={`table-header align-items-center sticky-header`}>
                    <TableRow
                      category={category}
                      dataList={dataList}
                      type="header"
                      cardIdFromURL={cardIdFromURL}
                      customClass="header-category"
                    />
                  </div>
                );
              })}
              {sortedCategoryGroups.map((group: any) => {
                const categoriesToBeShown = groupedCategories(sortedCategory, group.id);
                return (
                  <CardComparisonAccordion
                    categoriesToBeShown={categoriesToBeShown}
                    group={group}
                    pageItems={props?.pageItems}
                    styleClass={'accordion-wrapper'}
                  />
                );
              })}
            </div>
          ) : (
            <div className="skeleton-loader ">
              <Skeleton height="2.5rem" width={`col-12`} />
            </div>
          )}
        </div>
        <div className={'d-flex justify-content-center'}>
          <Button isLink={true} href="#" className={'my-5'} testId="buttonToTop">
            Return to Top
            <span className="icon-up-arrow ml-1" />
          </Button>
        </div>
      </div>
    </StyledCardComparison>
  );
};

export const CardComparison = (props: CardComparisonProps) => {
  return (
    <EditableComponent config={CardComparisonConfig} {...props}>
      <CardComparisonComp {...props} />
    </EditableComponent>
  );
};
