import { FC } from 'react';
import clsx from 'clsx';
import { StickyCtaProps } from './StickyCta.types';
import { StyledStickyCta } from './StickyCta.styles';
import { Button } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const StickyCtaConfig = {
  emptyLabel: 'stickycta',
  isEmpty: () => true,
  resourceType: `/components/content/stickycta`,
};

export const StickyCtaComp: FC<StickyCtaProps> = (props: StickyCtaProps) => {
  const {
    linkText,
    linkUrl,
    openInaNewTab,
    additionalTrackingVariables,
    trackingDescription,
    impressionTrack,
    trackingContentPosition,
    atCCeVar48,
    clickTrack,
    merchandisingCategory,
    impressionCount,
    trackingTag,
    trackingOfferType,
  } = props;

  const trackingProperties = {
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    clickTrack: clickTrack === 'true',
    impressionTrack: impressionTrack === 'true',
    impressionCount: impressionCount === 'true',
  };
  const linkType = openInaNewTab ? 'external' : 'internal';
  const clickTrackValue = `${trackingContentPosition}|${trackingDescription}|${linkType}|${additionalTrackingVariables}`;

  return (
    <StyledStickyCta data-testid="stickycta" data-component-name="o-cobrand-stickycta" className="pt-2 pb-4">
      <Button
        isLink
        href={linkUrl}
        trackingProperties={trackingProperties}
        target={openInaNewTab === 'true' ? '_blank' : '_self'}
        /* "track-completed" class is added to escape getNonCoBrandTrackValue method in clientlib.js */
        className={clsx('m-button-s sticky_cta', 'track-completed')}
        linkType={linkType}
        custom_click_track_value={clickTrackValue}
        buttonCopy={linkText}
        linkAriaLabelOpenNewWindow={openInaNewTab ? 'Open in new tab' : ''}
      ></Button>
    </StyledStickyCta>
  );
};

export const StickyCta = (props: StickyCtaProps) => {
  return (
    <EditableComponent config={StickyCtaConfig} {...props}>
      <StickyCtaComp {...props} />
    </EditableComponent>
  );
};
