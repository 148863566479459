// Styles for PointsDetails go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledPointsDetails = styled.div`
  .points-potentional-description {
    p {
      &:first-child {
        margin-bottom: ${toRem(32)};
        @media ${baseVariables.mediaQuery.lg} {
          margin-bottom: ${toRem(56)};
        }
      }
    }
    ul {
      padding-left: ${toRem(24)};
      @media ${baseVariables.mediaQuery.lg} {
        padding-left: ${toRem(40)};
      }
    }
  }
`;
