// Styles for CardComparison go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledCardComparison = styled.div`
  .table-container {
    box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
    border-radius: ${toRem(14)};
    background-color: ${baseVariables.color.merch50};

    .table-wrapper {
      .accordion-wrapper {
        &:last-child {
          .accordion-container {
            border-bottom: none;
          }
        }
      }
      .data-row {
        background-color: ${baseVariables.color.merch50};
        border-top: ${toRem(1)} ${baseVariables.border.borderStyleDefault} #f0f0f0;
      }
      .data-section {
        border-top: ${toRem(1)} ${baseVariables.border.borderStyleDefault} #f0f0f0;
        border-right: ${toRem(1)} ${baseVariables.border.borderStyleDefault} #f0f0f0;
        @media ${baseVariables.mediaQuery.md} {
          border: none;
        }
      }
      .data-cell {
        border-left: ${toRem(1)} ${baseVariables.border.borderStyleDefault} #f0f0f0;
      }
      .category-cell {
        background-color: #f4f4f4;
        @media ${baseVariables.mediaQuery.md} {
          background-color: ${baseVariables.color.merch50};
        }
        border-left: none;
        .cell-wrapper {
          justify-content: center;
        }
      }
      .table-header .category-cell {
        background-color: ${baseVariables.color.merch50};
      }
    }
  }
  .table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: ${toRem(1)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.neutral30};
    box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
    border-radius: ${toRem(14)} ${toRem(14)} 0 0;
    .data-row,
    .data-cell {
      border-radius: ${toRem(14)} ${toRem(14)} 0 0;
    }
  }
  .data-cell {
    &:last-child .badge {
      @media ${baseVariables.mediaQuery.md} {
        border-radius: 0 ${toRem(14)} 0 0;
      }
    }
  }
  .table-header,
  .sticky-header {
    top: 0;
  }
  .sticky-header {
    border-radius: ${toRem(14)} ${toRem(14)} 0 0;
    position: sticky;
  }
`;
