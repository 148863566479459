// Styles for CardComparisonAccordion go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledCardComparisonAccordion = styled.div`
  .accordion-container {
    border-bottom: ${toRem(1)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.neutral30};
    .accordion__title {
      padding: ${toRem(8)} ${toRem(16)};
      text-align: left;
    }
    .accordion__list {
      margin-top: 0;
      padding-bottom: 0;
    }
    .accordion__heading {
      .accordion__icon {
        margin-right: ${toRem(16)};
      }
    }
  }
`;
