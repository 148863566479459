// Styles for AwardsGallery go here.
import { baseVariables } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';

export const StyledAwardsGallery = styled.div`
  ${rtl`
    .award-gallery {
      &-seperator {
        border-right: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.alert20};
        &-even {
          &:nth-child(2) {
            border: none;
          }
        }
        &-odd {
          &:nth-child(3) {
            border: none;
          }
        }
      }
    }
  `}
`;
