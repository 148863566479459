// Imports for external libraries go here.
import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { PointsDetailsProps } from './PointsDetails.types';
import { StyledPointsDetails } from './PointsDetails.styles';
import { Heading, Types } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { Points } from '@marriott/mi-ui-library';
import { RichText } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const PointsDetailsConfig = {
  emptyLabel: 'Points Potential',
  isEmpty: () => true,
  resourceType: `/components/content/pointsdetails`,
};

export const PointsDetailsComp: FC<PointsDetailsProps> = (props: PointsDetailsProps) => {
  const { title, subTitle, noOfPoints, pointsDescription, componentID, cardName } = props;
  return (
    <StyledPointsDetails
      data-component-name="o-cobrand-pointsdetails"
      data-testid="pointsdetails"
      id={'pointsdetail' + componentID}
      className="pb-lg-5 pb-md-4 pb-2 mb-md-2 d-flex flex-column align-items-center t-background-color"
    >
      {cardName ? <span className="sr-only">{cardName}</span> : null}
      <>
        <Heading
          titleText={title}
          variation={Types.headingType.title}
          fontSize={Types.size.medium}
          customClass={clsx('text-center mt-5 pt-lg-5 pt-2 mb-md-5 mb-4 pb-2')}
        />
        <div className={clsx('m-message-inline px-md-5 px-3 pb-lg-4 pb-3 pt-2 col-lg-10 col-12')}>
          <Heading
            titleText={subTitle}
            variation={Types.headingType.subtitle}
            fontSize={Types.size.extraLarge}
            customClass={clsx('text-center mt-md-5 mt-4 mb-3')}
          />
          <div className="text-center mb-3">
            <Points text={noOfPoints} borderstyle="underlinedLarge"></Points>
          </div>
          <RichText
            text={pointsDescription}
            componentId={componentID}
            customClass="points-potentional-description text-center mx-lg-5 px-lg-4 pb-lg-3 pb-0"
          ></RichText>
        </div>
      </>
    </StyledPointsDetails>
  );
};

export const PointsDetails = (props: PointsDetailsProps) => {
  return (
    <EditableComponent config={PointsDetailsConfig} {...props}>
      <PointsDetailsComp {...props} />
    </EditableComponent>
  );
};
